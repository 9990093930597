import React from 'react';
import { LinkBox, LinkOverlay, Box, Text, Stack, Container, Flex, Button, Icon } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import HomeLayout from '../../../components/layouts/homelayout';
import PageHeader from '../../../components/sections/pageheader';

import Carousel from '../../../components/sections/carousel';
import Seo from '../../../components/sections/seo';

import naBanner from '../../../components/ui/regionalcareers/NAHero.jpg'

import customerCareImg from '../../../components/ui/regionalcareers/northamerica/Call-Center.jpg'
import dcImg from '../../../components/ui/regionalcareers/northamerica/Distribution.jpg'
import hqOfficeImg from '../../../components/ui/regionalcareers/northamerica/Headquarters_Updated.jpg'
import retailImg from '../../../components/ui/regionalcareers/northamerica/Retail.jpg'
import naCareers from '../../../components/ui/regionalcareers/northamerica/wallowas_nacareers.jpg'

export default function NATabPanel() {

    const jobs = [{
        id: 1,
        heading: 'Customer Care Centers',
        text: "The North America Region is home to Columbia Sportswear Company’s Global Call Center in Portland, Oregon. The company’s customer care representatives support consumers around the globe.",
        image: `${customerCareImg}`
    },
    {
        id: 2,
        heading: 'Distribution Centers',
        text: "North America Distribution Centers are located in Portland, Oregon; Robards, Kentucky and London, Ontario.",
        image: `${dcImg}`
    },
    {
        id: 3,
        heading: "Headquarter Office",
        text: "The North America Region includes multiple headquarter offices. Corporate Columbia Sportswear Company headquarters, the Columbia brand office and SOREL brand office are based in beautiful Portland, Oregon. Mountain Hardwear brand headquarters is located in Richmond, California and the prAna brand headquarters is based in Carlsbad, California. The Canada Region Office is based in London, Ontario.",
        image: `${hqOfficeImg}`
    },
    {
        id: 4,
        heading: 'Retail Stores',
        text: "The North America Region has over 130 retail store locations throughout Canada and the U.S. Retail locations represent specific brands, outlets or multi-brand stores.",
        image: `${retailImg}`
    }
    ];

  return (
    <HomeLayout>
        <Seo
            title="Careers in North America"
            description="Find your next job in retail, distribution, or at our corporate headquarters."
            //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />
        <PageHeader 
            heading='North America Region'
            bkgImg={naBanner}
        />
        <Container maxW={'6xl'} my={9}>
            <Box className='flow'>
                <Text>
                    Our birthplace of the Columbia brand and our largest region with the global headquarters in Portland, Oregon, we offer diverse opportunities across back office operations such as finance, HR, supply chain, IT and manufacturing, to sales, product creation, retail, and more! The North America Region is home to all of our Brand headquarters, three distribution centers and over 130 retail store locations across the U.S. and Canada.
                </Text>
                <Text>
                    A rich heritage with deep family values, our employees are proud of the products we create and how we collaborate together in continued growth and connecting active people with their passions. Collaboration, family spirit, true grit and pride are felt throughout the region and define our culture in our robust brand portfolio.
                </Text>
                <Carousel cards={jobs}></Carousel>
                <Stack my={10}>
                    <Flex 
                        minH={'375px'}
                        justify="center"
                        align="center"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.1)) , url(${naCareers})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        borderRadius={10}
                        flexDir={'column'}
                    >
                        <Text
                            whiteSpace="pre-line"
                            align="center"
                            color="white"
                            fontWeight="bold"
                            fontFamily="GerTT"
                            fontSize="4xl"
                            opacity={1.0}
                            textTransform={'uppercase'}
                            sx={{'--flow-lh': 'lineHeights.10'}}
                            py={3}>Check Out Jobs in North America
                        </Text>
                        <LinkBox as={'a'}>
                            <LinkOverlay
                                href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
                                isExternal
                                >
                                    <Button
                                        size="md"
                                        p={6}
                                        bg="black"
                                        color="white"
                                        borderRadius={8}
                                        mt={8}
                                        textTransform={'uppercase'}
                                        >
                                        Join the Team
                                        <Icon
                                            color="white"
                                            w={5}
                                            h={5}
                                            as={ExternalLinkIcon}
                                            pl="4px"
                                            ml={1}
                                        >
                                        </Icon>
                                    </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </Flex>
                </Stack>
            </Box>
        </Container>
    </HomeLayout>
  );
}
