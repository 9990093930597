import React from 'react';
import { Image, Box, Container, Text } from '@chakra-ui/react';
import Seo from '../../../components/sections/seo';

import sorelEvolution from '../../../components/ui/ourbrands/sorel/sorel_evolution.jpg';
import sorelCoreBeliefs from '../../../components/ui/ourbrands/sorel/sorel_core-beliefs.jpg';
import TextWithRightMedia from '../../../components/sections/textwithrightmedia';
import TextWithLeftMedia from '../../../components/sections/textwithleftmedia';

export default function SorelTabPanel() {
  return (
    <Box>
      <Seo
        title="Careers at SOREL"
        description="Find your next career at SOREL."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='7xl' my={14} className='flow'>

        <Box 
          as={'section'}
          className='flow'
          textAlign={'center'}>
          <h1>
              Creating Powerful Footwear for<br /> Unstoppable Individuals
          </h1>
          <Text
            className={'mw-prose'}
            mx={'auto'}
          >
            Here at SOREL, we believe you are your most powerful tool and the determination you find within is an unstoppable force. SOREL challenges the status quo by creating unexpected footwear that pushes the boundaries of function-first fashion. We stay in motion so you can push yourself forward.
          </Text>
        </Box>
        <Box as='section'
          sx={{'--flow-space': 'var(--space-m)'}}
          mt={'var(--space-m)'}
          >
          <TextWithRightMedia
            title={<h2>Our Core Beliefs</h2>}
            media={<Image width={'75%'} src={sorelCoreBeliefs} />}
            is_full_bleed={false}
            content={
              <>
              <p>
                At SOREL, we aspire to seize opportunities and make bold statements. We’re not satisfied unless we’re exhausting all possibilities. Our passion for improvement comes with a sense of urgency, we are relentless in finding the best solutions possible. We question, explore, disrupt and lead, all while looking for ways to be better. SOREL acts with purpose while making sure every decision is made with absolute intention. 
              </p>
              </>
            }
          />
        </Box>
        <Box as='section'>
          <TextWithLeftMedia 
            title={<h2>SOREL Evolution</h2>}
            media={<Image src={sorelEvolution} />}
            is_full_bleed={false}
            content={
              <>
              <p>
                Founded in 1962, SOREL combines expert craftsmanship and of-the-moment design to create all-season footwear. What began decades ago with premium boots, crafted with an unparalleled balance of construction and protection, is now a brand dedicated to both functionality and fashion. 
              </p>
              </>
            }
          />
        </Box>
        

        <Box 
          as={'section'}
          className='flow'
          textAlign={'center'}
          sx={{'--flow-space': 'var(--space-xl)'}}
          >
            <h2>Why Work at SOREL</h2>
             <Text
                className={'mw-prose'}
                mx={'auto'}
              >
                Being part of the SOREL tribe is for the few that dare to draw outside the lines. It’s a culture comprised of individuals audaciously pushing forward as one. New ideas fuel us as we explore the unknown, while never being afraid to charge the edge. SOREL is an institution of creators, doers and leaders who bring the spirit of the boot in everything we make.
              </Text>

              <Box 
                as='a'
                className='btn btn-black btn-normal pill-border'
                textAlign={'center'}
                display={'inline-block'}
                href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/SOREL_Careers?source_id=csccom'
                target='_blank'
                sx={{'--flow-space': 'var(--space-l)'}}
                >
                    See Current Openings
              </Box>
        </Box>
      </Container>
    </Box>
  )
}
