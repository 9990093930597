import React from 'react';
import { Container, Heading, SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel, Fade } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

// icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';

// Components
import GridCardNoLink from '../../../components/sections/gridcardnolink';
import CarouselSimple from '../../../components/sections/carouselsimple';

// Sub-regions
import franceCard from '../../../components/ui/regionalcareers/europe/FRANCE1-1.jpg'
import ukCard from '../../../components/ui/regionalcareers/europe/united-kingdom.jpg'
import spainCard from '../../../components/ui/regionalcareers/europe/SPAIN1.jpg'
import switzerlandCard from '../../../components/ui/regionalcareers/europe/SWITZERLAND1.jpg'
import germanyCard from '../../../components/ui/regionalcareers/europe/GERMANY1.jpg'
import italyCard from '../../../components/ui/regionalcareers/europe/ITALY1.jpg'
import netherlandsCard from '../../../components/ui/globalcareers/regions/europe/eu_netherlands.jpg';
import irelandCard from '../../../components/ui/globalcareers/regions/europe/eu_ireland.jpg';

// sections
import euFeatureImg from '../../../components/ui/globalcareers/regions/europe/eu_feature.jpg';
import ehq from '../../../components/ui/globalcareers/regions/europe/ehq.jpg';
import euRetailHoriImg from '../../../components/ui/globalcareers/regions/europe/eu_retail_hori.jpg';
import euDistributioniImg from '../../../components/ui/globalcareers/regions/europe/eu_distribution.jpg';

// Fill the carousels
import hrLegalImg from '../../../components/ui/globalcareers/carousel/hr_legal_finance.jpg';
import innovationImg from '../../../components/ui/globalcareers/carousel/innovation.jpg';
import salesImg from '../../../components/ui/globalcareers/carousel/sales.jpg';
import supplyChainImg from '../../../components/ui/globalcareers/carousel/supply_chain.jpg';
import technologyImg from '../../../components/ui/globalcareers/carousel/technology.jpg';
import maktImg from '../../../components/ui/globalcareers/carousel/marketing.jpg';
import custCareImg from '../../../components/ui/globalcareers/carousel/customer_care.jpg';
//import distImg from '../../../components/ui/globalcareers/carousel/distribution.jpg';

import productDesignImg from '../../../components/ui/globalcareers/carousel/product_design.jpg';
import facilitiesImg from '../../../components/ui/globalcareers/carousel/facilities_administration.jpg';
//import manufacturingImg from '../../../components/ui/globalcareers/carousel/manufacturing.jpg';
//import retailImg from '../../../components/ui/globalcareers/carousel/retail.jpg';

// Life at EMEA
import emeaPeopleImg from '../../../components/ui/globalcareers/regions/europe/emea_people.jpg';
import emeaPeopleDroneImg from '../../../components/ui/globalcareers/regions/europe/emea_people_2.jpg';

export default function EuropeTabPanel() {
    const genevajobs = [{
        id: 1,
        heading: 'Brand Marketing',
        image: `${maktImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
    },
    {
        id: 2,
        heading: 'Digital Sales',
        image: `${salesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
      },
      {
        id: 3,
        heading: 'Ecommerce',
        image: `${technologyImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
    },
    {
        id: 4,
        heading: 'HR',
        image: `${hrLegalImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
      },
      {
        id: 5,
        heading: 'Legal',
        image: `${facilitiesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
      },
      {
        id: 6,
        heading: 'Supply Chain (Planning, Logistics, & Customer Operations)',
        image: `${supplyChainImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
      },
    {
        id: 7,
        heading: 'Merchandising',
        image: `${productDesignImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=187134fccb084a0ea9b4b95f23890dbe'
    },
    ];

    const strasbourgjobs = [{
        id: 1,
        heading: 'Finance',
        image: `${salesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a'
    },
    {
        id: 2,
        heading: 'Customer Service',
        image: `${custCareImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a'
      },
      {
        id: 3,
        heading: 'Account Operations',
        image: `${technologyImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a'
    },
    ];

    const regionaljobs = [{
        id: 1,
        heading: 'Trade Marketing Manager',
        image: `${maktImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=187134fccb084a0ea9b4b95f23890dbe&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=04a05835925f45b3a59406a2a6b72c8a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=d004c0d1a6c84511ab048669fcdf9fd7'
    },
    {
        id: 2,
        heading: 'Trade Marketing Specialist',
        image: `${innovationImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=187134fccb084a0ea9b4b95f23890dbe&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=04a05835925f45b3a59406a2a6b72c8a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=d004c0d1a6c84511ab048669fcdf9fd7'
      },
      {
        id: 3,
        heading: 'Key Account (Sales) Representative',
        image: `${salesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/en-US/CSC_Careers?source_id=csccom&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=187134fccb084a0ea9b4b95f23890dbe&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=04a05835925f45b3a59406a2a6b72c8a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=d004c0d1a6c84511ab048669fcdf9fd7'
    },
    ];

  return (
    <HomeLayout>
        <Seo
            title="Careers in Europe"
            description="Find your next job in our European locations."
            //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />

        <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-2xl)'}}>
            <section class="divided-hero page-banner">
                <div class="dh__feature">
                    <div>
                        <h1>Europe Region</h1>
                        <p>Discover a world of diversity within our team of 700+ across 13 European countries, united by the common goal of elevating Columbia to the pinnacle of outdoor brands in Europe. Our omnichannel approach seamlessly integrates Columbia and SOREL brands' engaging in-store and online experiences. Whether your passion for being outdoors takes you to the tops of mountains, for a walk in the rain to the nearest coffee shop or backpacking through countries, we have a job for you.</p>
                    </div>
                    <div>
                        <img src={euFeatureImg} alt='' />
                    </div>
                </div>
                <div class="dh__feature-grid">
                    {/* <img src={PlaceHolder} alt='' />
                    <img src={PlaceHolder} alt='' />
                    <img src={PlaceHolder} alt='' />
                    <img src={PlaceHolder} alt='' /> */}
                </div>
            </section>
        </Container>

            <section className='ehq'>
                <div className='hero-main height-100 page-banner'>
                    <div className='hero-main__container'>
                    <div className='hero-main__inner-container'>
                        <div className='hero-main__inner center-left is-top is-left'>
                        <div className='container'>
                            <div className='hero-main__content m-width-80'>
                                <h2>Headquarters</h2>
                                <p>Nestled at the heart of the Alps in Geneva, Switzerland, our European headquarters hosts the Executive Committee, go-to-market functions, and Planning and Logistics teams. In Schiltigheim, near Strasbourg, our shared services center thrives with over 100 professionals managing Finance and Operations/Customer Service. In addition to our corporate offices, the Sales and Trade Marketing teams are displayed in 3 regions across Europe, close to their customers and markets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='hero-main__video-container'>
                            <img src={ehq} alt='' />
                        </div>
                        <div className='hero-main__overlay'></div>
                    </div>
                    </div>
                </div>
            </section>

            <section>
                <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow'>
                    <Heading
                        className='section-heading'
                        fontSize={'var(--step-3)'}
                        textTransform={'none'}
                        mb={'var(--space-l)'}
                    >
                            Choose your destination
                    </Heading>
                    <Tabs isLazy position={'relative'} variant='soft-rounded'>
                        <TabList>
                            <Tab>Geneva</Tab>
                            <Tab>Strasbourg</Tab>
                            <Tab>Regional Roles</Tab>
                        </TabList>
                        
                        <TabPanels className='eu-jobs'>
                            <TabPanel className='geneva-jobs'>
                                <Fade in={true}>
                                    <CarouselSimple cards={genevajobs}></CarouselSimple>
                                </Fade>
                               
                            </TabPanel>
                            
                            <TabPanel className='strasbourg-jobs'>
                                <Fade in={true}>
                                    <CarouselSimple cards={strasbourgjobs}></CarouselSimple>
                                </Fade>
                            </TabPanel>

                            <TabPanel className='regional-jobs'>
                                <Fade in={true}>
                                    <CarouselSimple cards={regionaljobs}></CarouselSimple>
                                </Fade>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    
                </Container>
            </section>

            <section>
                <div className='hero-main height-100 page-banner'>
                    <div className='hero-main__container'>
                    <div className='hero-main__inner-container'>
                        <div className='hero-main__inner center-left is-top is-left'>
                        <div className='container'>
                            <div className='hero-main__content m-width-80'>
                                <h2>Retail</h2>
                                <p>With over 30 Columbia boutiques, we can be found in all major European outlet centers. Our retail teams are at the center of our business, connecting our customers with the products that fit their lifestyle. Whether you’re an outdoor enthusiast, a student looking for a side-job or a way to ignite your passion in the retail space, we welcome you to join us. </p>
                            </div>
                        </div>
                        </div>
                        <div className='hero-main__video-container'>
                            <img src={euRetailHoriImg} alt='' />
                        </div>
                        <div className='hero-main__overlay'></div>
                    </div>
                    </div>
                </div>
                <Container maxW={{base: 'max', lg: "1080px" }} mt={'var(--space-2xl)'} className='flow'>
                    <h3>Our Locations</h3>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                        <GridCardNoLink
                            title='France'
                            backgroundImage={franceCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='United Kingdom'
                            backgroundImage={ukCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Spain'
                            backgroundImage={spainCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Austria'
                            backgroundImage={switzerlandCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Germany'
                            backgroundImage={germanyCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Italy'
                            backgroundImage={italyCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Netherlands'
                            backgroundImage={netherlandsCard}
                            href='#'
                            />
                        <GridCardNoLink
                            title='Ireland'
                            backgroundImage={irelandCard}
                            href='#'
                            />
                    </SimpleGrid>
                </Container>
            </section>

            <section>
                <div className='hero-main height-100 page-banner'>
                    <div className='hero-main__container'>
                    <div className='hero-main__inner-container'>
                        <div className='hero-main__inner center-left is-top is-left'>
                        <div className='container'>
                            <div className='hero-main__content m-width-80'>
                                <h2>Distribution</h2>
                                <p>We have a distribution center in Cambrai, located in the north of France, from where we ship products to customers throughout Europe.</p>
                            </div>
                        </div>
                        </div>
                        <div className='hero-main__video-container'>
                            <img src={euDistributioniImg} alt='' />
                        </div>
                        <div className='hero-main__overlay'></div>
                    </div>
                    </div>
                </div>
                
            </section>

            <section className='benefits-and-perks'>
                <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-m)'}}>
                    <h2>Benefits & Perks</h2>

                    <div class="auto-gridx3">
                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon dark-blue">
                                    <CalendarMonthOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Hybrid Schedule for Corporate Positions (Strasbourg and Geneva)</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon blue-green">
                                    <DiscountOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Retail Discounts on Columbia and SOREL products</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                        <div class="wt-card__content">
                        <div class="bubble-icon green-gray">
                            <ModelTrainingOutlinedIcon className='icon-in-bubble' />
                        </div>

                        <div class="wt-card__text">
                        <h4>Training, Learning and Development programs</h4>
                        </div>
                        </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                        <div class="wt-card__content">
                            <div class="bubble-icon dark-green">
                                <RouteOutlinedIcon className='icon-in-bubble' />
                            </div>

                        <div class="wt-card__text">
                        <h4>Career Development opportunities</h4>
                        </div>
                        </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                        <div class="wt-card__content">
                        <div class="bubble-icon brown">
                            <SelfImprovementOutlinedIcon className='icon-in-bubble' />
                        </div>

                        <div class="wt-card__text">
                        <h4>Pilates and yoga classes, running groups or sport activities</h4>
                        </div>
                        </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='life-in-emea'>
                <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-m)'}}>
                    <h2>Life in EMEA</h2>
                    <p>Joining Columbia Sportswear Company in EMEA is being part of a multicultural team spread across over 10 countries across Europe, where everyone can make an impact, whatever the location, your status or job function. Our Employees are ambassadors of our brands and our ongoing efforts to make the outdoors accessible for everyone.</p>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                        <img src={emeaPeopleImg} alt='People playing musical instruments' />
                        <img src={emeaPeopleDroneImg} alt='A group of people at an outdoor event' />
                    </SimpleGrid>
                </Container>
            </section>
    </HomeLayout>
  );
}
